import { g as getElement } from './index-6dad2db9.js';
import { i as isSSR } from './element-d46853d9.js';
import { a as SubscriptionList } from './subscription-list.class-9c42aac2.js';

const SUBSCRIPTIONS_KEY = 's-subscriptions';
const LONG_PRESS_ENABLE_SUBJECT_KEY = 'long-press-enable-subject';
// eslint-disable-next-line dot-notation
const IS_TOUCH = !isSSR() && ('ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator['msMaxTouchPoints'] > 0);
const MS_POINTER_ENABLED = !isSSR() && window.navigator && 'msPointerEnabled' in window.navigator;
const HAS_POINTER_EVENTS = !isSSR() && ('PointerEvent' in window || MS_POINTER_ENABLED);
const EVENT_MOUSEDOWN = HAS_POINTER_EVENTS ? 'pointerdown' : IS_TOUCH ? 'touchstart' : 'mousedown';
const EVENT_MOUSEUP = HAS_POINTER_EVENTS ? 'pointerup' : IS_TOUCH ? 'touchend' : 'mouseup';
const EVENT_MOUSEMOVE = HAS_POINTER_EVENTS ? 'pointermove' : IS_TOUCH ? 'touchmove' : 'mousemove';
const DEFAULT_MAX_DIFF_X = 10;
const DEFAULT_MAX_DIFF_Y = 10;
const DEFAULT_DURATION = 800;
function LongPress(opts) {
  return (proto, methodName) => {
    const { componentDidLoad, disconnectedCallback, connectedCallback } = proto;
    const timerAttrName = [methodName, 'timer'].join('-');
    const startXAttrName = [methodName, 'startX'].join('-');
    const startYAttrName = [methodName, 'startY'].join('-');
    const clearTimerMethodName = ['clearLongPressTimer', methodName].join('-');
    const mouseDownHandlerMethodName = ['mouseDownHandler', methodName].join('-');
    const mouseMoveHandlerMethodName = ['mouseMoveHandler', methodName].join('-');
    function removeListeners(self) {
      if (!isSSR()) {
        const host = getElement(self);
        window.removeEventListener(EVENT_MOUSEUP, self[clearTimerMethodName], true);
        window.removeEventListener(EVENT_MOUSEMOVE, self[mouseMoveHandlerMethodName], true);
        window.removeEventListener('wheel', self[clearTimerMethodName], true);
        window.removeEventListener('scroll', self[clearTimerMethodName], true);
        host.removeEventListener(EVENT_MOUSEDOWN, self[mouseDownHandlerMethodName], true);
      }
    }
    function addListeners(self) {
      removeListeners(self);
      if (!isSSR()) {
        const host = getElement(self);
        window.addEventListener(EVENT_MOUSEUP, self[clearTimerMethodName], true);
        window.addEventListener(EVENT_MOUSEMOVE, self[mouseMoveHandlerMethodName], true);
        window.addEventListener('wheel', self[clearTimerMethodName], true);
        window.addEventListener('scroll', self[clearTimerMethodName], true);
        host.addEventListener(EVENT_MOUSEDOWN, self[mouseDownHandlerMethodName], true);
      }
    }
    function init(self) {
      const host = getElement(self);
      const method = self[methodName];
      !(host[SUBSCRIPTIONS_KEY] instanceof SubscriptionList) && (host[SUBSCRIPTIONS_KEY] = new SubscriptionList());
      const subscriptions = host[SUBSCRIPTIONS_KEY];
      self[timerAttrName] = null;
      self[startXAttrName] = 0;
      self[startYAttrName] = 0;
      self[clearTimerMethodName] = () => {
        clearTimeout(self[timerAttrName]);
        self[timerAttrName] = null;
      };
      self[mouseDownHandlerMethodName] = (e) => {
        var _a;
        self[startXAttrName] = e.clientX;
        self[startYAttrName] = e.clientY;
        (_a = self[clearTimerMethodName]) === null || _a === void 0 ? void 0 : _a.call(self);
        self[timerAttrName] = setTimeout(() => method.call(self, e), (opts === null || opts === void 0 ? void 0 : opts.duration) || DEFAULT_DURATION);
      };
      self[mouseMoveHandlerMethodName] = (e) => {
        var _a;
        const diffX = Math.abs(self[startXAttrName] - e.clientX);
        const diffY = Math.abs(self[startYAttrName] - e.clientY);
        if (diffX >= (DEFAULT_MAX_DIFF_X) || diffY >= ((opts === null || opts === void 0 ? void 0 : opts.maxDiffY) || DEFAULT_MAX_DIFF_Y)) {
          (_a = self[clearTimerMethodName]) === null || _a === void 0 ? void 0 : _a.call(self);
        }
      };
      subscriptions[LONG_PRESS_ENABLE_SUBJECT_KEY] = opts.enabled$(self).subscribe(enabled => {
        if (enabled) {
          addListeners(self);
        }
        else {
          removeListeners(self);
        }
      });
    }
    proto.componentDidLoad = function () {
      init(this);
      return componentDidLoad === null || componentDidLoad === void 0 ? void 0 : componentDidLoad.call(this);
    };
    proto.connectedCallback = function () {
      init(this);
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
    proto.disconnectedCallback = function () {
      const host = getElement(this);
      const subscriptions = host === null || host === void 0 ? void 0 : host[SUBSCRIPTIONS_KEY];
      subscriptions && subscriptions instanceof SubscriptionList && subscriptions.unsubscribeSafe(LONG_PRESS_ENABLE_SUBJECT_KEY);
      removeListeners(this);
      return disconnectedCallback === null || disconnectedCallback === void 0 ? void 0 : disconnectedCallback.call(this);
    };
  };
}

export { LongPress as L };
